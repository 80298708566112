import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import Seo from "../components/seo";
import "../style/wall.less";

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      winHeight: "100vh"
    };
  }

  setWindowHeight = () => {
    this.setState({
      winHeight: window.innerHeight
    });
  }

  render() {
    return (
      <Layout placeholder={false}>
        <Seo
          lang="en"
          title={this.props.data.site.siteMetadata.title}
        />
        <div
          className="wall"
          style={{ height: this.state.winHeight + "px" }}
        >
          <div className="intro container">
            <div className="main-title text-primary">
              <img alt="Jon's Animated Avatar" height="200px" src="/images/cov.jpg" style={{ borderRadius: "50%" }} />
            </div>
            <p className="tag-line text-secondary">
              {this.props.data.site.siteMetadata.introTag}
            </p>
            <p className="caption text-tertiary">
              {this.props.data.site.siteMetadata.description}
              {/* <br/> */}
              {/* {this.props.data.site.siteMetadata.descriptionTwo} */}
            </p>
            <p className="caption text-tertiary">
              Rails + JAMstack
              {/* {this.props.data.site.siteMetadata.descriptionCode} */}
            </p>
            <div>
              <Link to={'/blog/'} className="btn" style={{ margin: '10px 0'}}>
                LATEST POSTS 📝
              </Link>
            </div>
            <div>
              <a href={'https://sullyshoots.com/'} rel="noreferrer nofollow" target="_blank" className="btn" style={{ margin: '10px 0'}}>
                LATEST PICS 📸
              </a>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const query = graphql`
    query {
        site {
          siteMetadata {
            title
            capitalizeTitleOnHome
            titleImage
            introTag
            description
            descriptionTwo
            descriptionCode
            social {
              name
              url
              icon
            }
          }
        }
      }     
`;
